// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2018, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

import './public-path'

async function boot() {
  /* In order to provide translation anywhere/anytime, setIntl must be called before importing app components*/
  // In order to support the React Module Federation, we need to delay the loading react-intl inside
  // boot function (instead of using the import at top of this file) and then call setIntl after
  // the react-intl module is loaded.
  await import('Uif/lib/i18n/react-intl').then(loadedModule => {
    loadedModule.setIntl()
  })

  /* Load and execute whatever is needed before rendering the app*/

  /* Import BaseApp and start rendering */

  try {
    const cloudAdminImport = await import(
      /* webpackMode: "lazy", webpackChunkName: "cloudApp" */
      './CloudAdmin'
    )
    const renderAppImport = await import(
      /* webpackMode: "lazy", webpackChunkName: "commonEntry" */
      'Apps/commonEntry'
    )

    const cloudAdmin = cloudAdminImport.default
    const renderApp = renderAppImport.default
    renderApp(cloudAdmin)
  } catch (error) {
    try {
      // Try reloading the page, but not more than once.

      if (window.localStorage.getItem('cloud-admin-reloaded-once') !== 'true') {
        console.error(`Error loading cloud admin app (retrying once): ${error}`)
        window.localStorage.setItem('cloud-admin-reloaded-once', 'true')
        window.location.reload()
      } else {
        console.error(`Error loading cloud admin app (not retrying): ${error}`)
        throw error
      }
    } catch (localStorageError) {
      // If we can't access local storage, just surface the original error.
      console.error(
        `Error loading cloud admin app (no local storage access, not retrying): ${error}`
      )
      throw error
    }
  }
}
boot().catch(console.error)
